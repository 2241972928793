// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Autocomplete, Button, Center, Group, Loader, Text } from '@mantine/core';
import { Calendar, isSameDate } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { IconUser } from '@tabler/icons';
import { TestS } from '../../TestS';
import ContactAutocomplete from '../../Organismns/Contact/ContactAutocomplete';
import { ToastS } from '../../Service/ToastS';
import { filterContactInfosFromString } from '../../Utils/utils';
import { EventS } from '../../Service/EventS';
import { saveContact } from '../../Service/restapi/contactService';
import PanePaper from '../../Molecules/PanePaper';
import { ValidationS } from '../../Service/ValidationS';
import { EventKind } from '../../Types/EventT';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import { DateUtils, toUTC } from '../../Utils/dateUtils';

export const openCreateEventModal = () =>
  openModal({
    size: 'md',
    children: <CreateEventModal />,
    title: (
      <Text size="xl" weight="bolder">
        Event erstellen
      </Text>
    ),
  });

const CreateEventModal = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<any>();
  const [date, setDate] = useState<Date | null>(null);
  const [errorMessage, setError] = useState<string>();
  const [kind, setKind] = useState<string>();
  const [loadingKinds, setLoadingKinds] = useState(true);
  const [kinds, setKinds] = useState<EventKind[]>([]);
  const kindNames = kinds.map((_kind) => _kind.name);

  // const _setDates = (eventDates: Date[]) => setDates(DateUtils.sortDates(eventDates));

  useEffect(() => {
    setLoadingKinds(true);
    EventS.fetchEventKinds()
      .then(setKinds)
      .finally(() => setLoadingKinds(false));
  }, []);

  const save = async () => {
    if (!date) {
      ToastS.error('event-date-missing', 'Gib ein Eventdatum an.');
      return;
    }
    if (!contact) {
      ToastS.error('contact-missing', 'Lege einen Kontakt an oder wähle einen aus.');
      return;
    }
    let tmpContact: any = contact;
    let customerId = tmpContact.id;

    if (contact && !contact.id) {
      tmpContact = filterContactInfosFromString(contact.personDescription);
      tmpContact = {
        ...tmpContact,
        contactType: 'PRIVATE',
      };
      if (!ValidationS.validateAwsContactProps(tmpContact)) {
        setError(i18n._('error.input-field.invalid-aws-char'));
        return;
      }
      if (errorMessage) {
        setError(undefined);
      }
      setLoading(true);
      const resp = await saveContact(tmpContact);
      if (resp.ok) {
        const newContact = await resp.json();
        customerId = newContact.id;
      }
    }
    const newEvent = await EventS.createEventV2({ customerId, dates: [toUTC(date)], kind });
    setLoading(false);
    if (newEvent) {
      MixpanelS.track(ConstantS.TrackingEvents.EventCreated);
      const { id: eventId } = newEvent;
      closeAllModals();
      navigate(`/events/${eventId}/details`);
    }
  };

  // const isDateSelected = (date: Date) => {
  //   for (let i = 0; i < dates.length; i++) {
  //     if (isSameDate(dates[i], date)) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // const renderDate = (date: Date) => {
  //   const day = date.getDate();
  //   const selected = isDateSelected(date);
  //   const today = isSameDate(date, new Date());

  //   return (
  //     <Text weight={selected || today ? 'bolder' : 'normal'} color={selected ? 'white' : today ? 'blue' : 'inherit'}>
  //       {day}
  //     </Text>
  //   );
  // };

  return (
    <div>
      <Center mb="md" mt="sm">
        <PanePaper withShadow withoutBorder>
          <Calendar fullWidth value={date} minDate={new Date()} onChange={setDate} />
        </PanePaper>
      </Center>
      {/* <Group mt="md" mb="md">
        {dates.map((eventDate) => (
          <Text
            key={eventDate.getTime()}
            size="sm"
            p="xs"
            weight="bolder"
            sx={(theme) => ({
              borderRadius: theme.radius.sm,
              backgroundColor: theme.colors.gray[0],
            })}
          >
            🗓️ {EventS.formatEventDate(eventDate)}
          </Text>
        ))}
      </Group> */}
      <ContactAutocomplete
        icon={<IconUser size={16} />}
        setContact={setContact}
        testId={TestS.Events.Create.CONTACT_SELECT}
        label="Kunde neu anlegen oder vorhandene auswählen"
        placeholder="Max Mustermann max@musterfirma.de"
      />
      {errorMessage && (
        <Text size="xs" color="red">
          {errorMessage}
        </Text>
      )}
      <Autocomplete
        mt="sm"
        label="Art des Events"
        data={kindNames}
        defaultValue={kind}
        rightSection={loadingKinds && <Loader size="xs" />}
        onChange={(val) => val && setKind(val)}
        sx={{ textAlign: 'left' }}
      />
      <Center>
        <Button
          sx={{ width: 250 }}
          mt={40}
          mb="sm"
          onClick={save}
          disabled={loading || !contact}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </div>
  );
};

export default CreateEventModal;
