// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Menu, Space, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { IconBallpen, IconExternalLink } from '@tabler/icons';
import { useParams } from 'react-router-dom';
import { EventLocationContextProps, LocationDto } from '../../../Types/LocationT';
import { OwnLocationDistance } from '../LocationDistance';
import { openLocationModal } from '../Workflows/LocationModal';
import { LocationS } from '../../../Service/LocationS';
import BookitupBadge from '../../../Atoms/BookitupBadge';
import { EventLocationContext } from '../../../Service/Context/EventLocationContext';
import { EventContext } from '../../../Service/Context/EventContext';

interface Props {
  index: number;
  location: LocationDto;
}

const LocationItem: FC<Props> = ({ index, location }) => {
  const { id } = useParams();
  const { detachLocation } = useContext<any>(EventContext);
  const { locations, handlers, markAsPrimary } = useContext<any>(EventLocationContext) as EventLocationContextProps;
  const { id: locationId, phoneNumber, website, useAsPrimary } = location;
  const { setItem, remove } = handlers;
  const eventId = Number(id);

  const onEdit = () => {
    const onFinish = (loc: LocationDto) => setItem(index, loc);
    openLocationModal({ eventId, location, onFinish });
  };

  const onRemove = async () => {
    const deleted = await LocationS.deleteLocation(locationId);
    if (deleted) {
      remove(index);
      detachLocation(locationId);
    }
  };

  return (
    <Box
      pos="relative"
      p="md"
      sx={{ borderRadius: 10, backgroundColor: '#F1F1F1', overflowx: 'auto', overflowY: 'inherit' }}
    >
      {useAsPrimary && <BookitupBadge mb="sm" title="primär" />}
      <Text mb="xs" weight="bolder" className="overflow-ellipsis" sx={{ width: '80%' }}>
        {LocationS.getDescription(location)}
      </Text>
      <Menu shadow="md" width={170} position="bottom-end" transition="scale-y">
        <Menu.Target>
          <Box sx={{ position: 'absolute', right: 10, top: 15, cursor: 'pointer' }}>
            <IconBallpen size={18} />
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          {!useAsPrimary && <Menu.Item onClick={() => markAsPrimary(locationId)}>Als primär markieren</Menu.Item>}
          <Menu.Item onClick={onEdit}>Bearbeiten</Menu.Item>
          {(!useAsPrimary || (useAsPrimary && locations.length === 1)) && (
            <Menu.Item color="red" onClick={onRemove}>
              Löschen
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <OwnLocationDistance location={location} />
      <Text size="xs">{LocationS.getAddressDescription(location)}</Text>
      {phoneNumber && (
        <Text weight="lighter" size="xs" component="a" href={`tel:${phoneNumber}`}>
          {phoneNumber}
        </Text>
      )}
      {website && (
        <>
          <Space h="xs" />
          <Text
            component="a"
            href={website}
            target="_blank"
            color="blue"
            className="overflow-ellipsis"
            sx={{ display: 'inline-block', maxWidth: '100%' }}
          >
            <IconExternalLink size={16} style={{ marginBottom: 4 }} /> {website}
          </Text>
        </>
      )}
    </Box>
  );
};

export default LocationItem;
