import { EventDate } from '../Types/Event';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = `${process.env.REACT_APP_EVENT_SERVICE_URL}/eventDates`;

const create = async (eventId: string, payload: Partial<EventDate>): Promise<EventDate | null> => {
  const res = await ajaxActions.post(`${BASE_URL}?eventId=${eventId}`, payload);
  if (res.status === 201) {
    ToastS.success('event-date-created', 'Veranstaltungsdatum erstellt.');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const edit = async (
  id: number,
  patch: Partial<EventDate>,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<EventDate | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  if (interceptViolation) {
    interceptViolation(res);
  } else {
    ToastS.generalError();
  }
  return null;
};

const remove = async (id: number, interceptViolation: (httpResponse: Response) => void): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${id}`);
  if (res.ok) {
    ToastS.info('event-date-deleted', 'Veranstaltungsdatum gelöscht.');
    return true;
  }
  interceptViolation(res);
  return false;
};

const assignPrimaryLocation = async (locationId: number, eventId: number) => {
  const res = await ajaxActions.patch(`${BASE_URL}/primary-location?locationId=${locationId}&eventId=${eventId}`, null);
  return res.ok;
};

// eslint-disable-next-line import/prefer-default-export
export const EventDateS = {
  create,
  edit,
  remove,
  assignPrimaryLocation,
};
