import { Outlet, Route, Routes } from 'react-router-dom';
import { useContext } from 'react';
import Settings from './Templates/Settings/Settings';
import DocumentTemplate from './Organismns/Settings/Documents/DocumentTemplates/DocumentTemplate';
import MessageTemplateProvider from './Templates/Settings/Emails/MessageTemplateProvider';
import ContractTemplateContextProvider from './Organismns/Settings/Documents/ContractTemplates/ContractTemplateContextProvider';
import Statistics from './Templates/Statistics/Statistics';
import RecommendationRequestLoggedIn from './Templates/Recommendation/RecommendationRequestLoggedIn';
import DocumentsPage from './Organismns/Tables/DocumentsPage';
import QuestionnaireTemplateContextProvider from './Organismns/Settings/Documents/QuestionnaireTemplates/QuestionnaireTemplateContextProvider';
import { DocumentContextProviderProxy } from './Service/Context/DocumentContext';
import RecommendationRequest from './Templates/Recommendation/RecommendationRequest';
import { DocumentLayoutContextProviderProxy } from './Service/Context/DocumentLayoutContext';
import PortalLayout from './Templates/Portal/PortalLayout';
import AccountSettings from './Templates/Settings/Account/AccountSettings';
import Accounting from './Templates/Settings/Accounting/Accounting';
import Bills from './Templates/Settings/Billing/Bills';
import PackageSection from './Templates/Settings/Billing/PackageSection';
import CalendarConfig from './Templates/Settings/Calendar/CalendarConfig';
import CustomFields from './Templates/Settings/CustomFields/CustomFields';
import DocumentTemplateList from './Templates/Settings/Documents/DocumentTemplates/DocumentTemplateList';
import { SequentialNumbers } from './Templates/Settings/Documents/SequentialNumbers';
import { Taxes } from './Templates/Settings/Documents/Taxes';
import MailAccount from './Templates/Settings/Emails/MailAccount';
import MessageTemplateList from './Templates/Settings/Emails/MessageTemplateList';
import SignaturePane from './Templates/Settings/Emails/SignaturePane';
import PromoMaterial from './Templates/Settings/FileUploads/PromoMaterial';
import { GDPR } from './Templates/Settings/GDPR';
import QuestionnaireTemplates from './Templates/Settings/QuestionnaireTemplates/QuestionnaireTemplates';
import RecruitmentCampaign from './Templates/Settings/Recruitment/RecruitmentSection';
import Workflow from './Templates/Settings/Reminder/Workflow';
import Team from './Templates/Settings/Team/Team';
import { ConnectWebsiteProxy } from './Templates/Settings/WebHooks/ConnectWebsite';
import Messaging from './Templates/Emails/Messaging';
import EmailInboxPage from './Templates/Emails/EmailInboxPage';
import EventDetails from './Templates/Event/EventDetails';
import EventResources from './Templates/Resource/EventResources';
import EventProfit from './Organismns/EventProfit';
import EventDocuments from './Templates/Event/EventDocuments';
import { Contracts } from './Organismns/Contract/Contracts';
import { QuestionnaireContextProviderProxy } from './Service/Context/QuestionnaireContext';
import { ContractContextProviderProxy } from './Service/Context/ContractContext';
import SharePane from './Organismns/Share/SharePane';
import QuestionnaireForm from './Templates/Fragebögen/QuestionnaireForm';
import EventContractForm from './Templates/Contract/EventContractForm';
import ContractTemplate from './Molecules/Settings/ContractTemplates/ContractTemplate';
import QuestionnaireTemplate from './Molecules/Settings/QuestionnaireTemplate/QuestionnaireTemplate';
import { EmailTemplateEditorSkeleton } from './Organismns/Settings/EmailTemplateEditorSkeleton';
import EventForm from './Templates/Event/EventForm';
import DocumentSettings from './Templates/Settings/Documents/DocumentSettings';
import Tasks from './Organismns/Tasks/Tasks';
import ConfirmInvitationPage from './Templates/Security/ConfirmInvitationPage';
import TaskEditModal from './Molecules/Event/Tasks/TaskEditModal';
import TaskCreateModal from './Molecules/Event/Tasks/TaskCreateModal';
import LoginPage from './Templates/Security/LoginPage';
import RegisterPage from './Templates/Security/RegisterPage';
import BookitupTemplate from './Templates/BookitupTemplate';
import RegistrationCompleted from './Templates/Security/RegistrationCompleted';
import PasswordForgotten from './Templates/Security/PasswordForgotten';
import EmailVerificationPage from './Templates/Security/EmailVerificationPage';
import PasswordResetPage from './Templates/Security/PasswordResetPage';
import RecurringTaskModalFlow from './Templates/Settings/Reminder/RecurringTaskModalFlow';
import CustomAttributeDeleteModalFlow from './Templates/Settings/CustomFields/CustomAttributeDeleteFlow/CustomAttributeDeleteModalFlowParent';
import ShareModalFlow from './Organismns/Share/ShareFlow/ShareModalFlowParent';
import InsertPortalLink from './Organismns/Message/InsertPortalLink';
import DocumentForm from './Templates/Documents/DocumentForm';
import EventDocumentForm from './Templates/Event/EventDocumentForm';
import EventContextProviderProxy from './Service/Context/proxy/EventContextProviderProxy';
import { EventPortalContextProvider } from './Service/Context/EventPortalContext';
import DocumentPreview from './Molecules/Document/DocumentPreview';
import ContractPreview from './Templates/Contract/ContractPreview';
import QuestionnairePreview from './Templates/Fragebögen/QuestionnairePreview';
import { ContractTemplates } from './Templates/Settings/Documents/Contract/ContractTemplates';
import { ContactContextProvider } from './Service/Context/ContactContext';
import { ProfileContextProvider } from './Service/Context/ProfileContext';
import { ApplicationUserContextProvider } from './Service/Context/ApplicationUserContext';
import { BankAccountContextProvider } from './Service/Context/BankAccountContext';
import { AnnoucementsContextProvider } from './Service/Context/AnnouncementsContext';
import { AuthContext } from './Service/Context/AuthContext';
import LoxOAuthDispatcher from './Molecules/Lexoffice/OAuthDispatcher';
import ContactFormList from './Templates/Settings/ContactFormBuilder/ContactFormList';
import PositionsTable from './Organismns/Settings/Documents/Positions/PositionsTable';
import BaseTable from './Organismns/Tables/BaseTable';
import ResourcesPage from './Organismns/Resources/ResourcesPage';
import ResourcePage from './Organismns/Resources/ResourcePage';
import ResourceDetails from './Organismns/Resources/ResourceDetails';
import { NotificationsContextProvider } from './Service/Context/NotificationsContext';
import WorkflowTemplate from './Templates/Settings/Workflow/WorkflowTemplate';
import ContactsPage from './Templates/Contacts/ContactsPage';
import GoogleSection from './Templates/Settings/Google/GoogleSection';
import { EventsContextProvider } from './Service/Context/EventsContext';
import EventsPage from './Templates/EventList/EventsPage';
import AllResources from './Templates/Resource/AllResources';
import ResourcesPane from './Templates/Settings/Resources/ResourcesPane';
import ResourceStockIntervals from './Organismns/Resources/stocks/ResourceStockIntervals';
import ResoucePlannings from './Organismns/Resources/planning/ResoucePlannings';
import { EventResourcePlanningContextProvider } from './Service/Context/EventResourcePlanningContext';
import { AddonContextProvider } from './Service/Context/ResourceAddonsContext';
import { ViolationContextProvider } from './Service/Context/ViolationContext';
import { BookitupFormContextProvider } from './Service/Context/BookitupFormContext';
import ContactForm from './Templates/Settings/ContactFormBuilder/ContactForm';
import { MessageContextProvider } from './Service/Context/MessageContextV2';
import MessagingWrapper from './Templates/Emails/MessagingWrapper';
import ManagementPage from './Templates/Management/ManagementPage';
import EventKinds from './Templates/Settings/EventKinds';
import EventStates from './Templates/Settings/CustomStates';
import { ICalContextProvider } from './Service/Context/ICalContext';
import AcademyChannel from './Organismns/Academy/AcademyChannel';
import AcademyPage from './Organismns/Academy/AcademyPage';
import AcademyLandingPage from './Organismns/Academy/AcademyLandingPage';
import AzureOAuthDispatcher from './Templates/Settings/Emails/oauth/AzureOAuthDispatcher';

const ApplicationRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Routes>
      {/* AUTH ROUTES  */}
      {isAuthenticated && (
        <Route path="" element={<ApplicationUserContextProvider />}>
          <Route path="" element={<ProfileContextProvider />}>
            <Route path="" element={<ContactContextProvider />}>
              <Route path="" element={<NotificationsContextProvider />}>
                <Route path="" element={<AddonContextProvider />}>
                  <Route path="" element={<ViolationContextProvider />}>
                    <Route path="" element={<AnnoucementsContextProvider />}>
                      <Route path="" element={<EventsContextProvider />}>
                        <Route path="" element={<ICalContextProvider />}>
                          <Route path="" element={<EventsPage />} />
                        </Route>
                      </Route>
                    </Route>
                    <Route path="management" element={<ManagementPage />} />
                    <Route path="contacts" element={<ContactsPage />} />
                    <Route path="statistics" element={<Statistics />} />
                    <Route path="recommendationRequest/:id" element={<RecommendationRequestLoggedIn />} />
                    <Route path="tasks" element={<Tasks />} />

                    <Route path="resources" element={<ResourcesPage />}>
                      <Route path="all" element={<AllResources />} />
                    </Route>
                    <Route path="resources/:id" element={<ResourcePage />}>
                      <Route path="details" element={<ResourceDetails />} />
                      <Route path="stock-intervals" element={<ResourceStockIntervals />} />
                      <Route path="planning" element={<ResoucePlannings />} />
                    </Route>

                    <Route path="documents" element={<DocumentsPage />}>
                      <Route path="" element={<BaseTable />} />
                      <Route path="" element={<DocumentContextProviderProxy />}>
                        <Route path=":documentId" element={<DocumentForm />} />
                        <Route path=":documentId/preview" element={<DocumentPreview />} />
                      </Route>
                    </Route>

                    <Route path="postbox" element={<EmailInboxPage />}>
                      <Route path="" element={<MessageContextProvider />}>
                        <Route path=":box" element={<MessagingWrapper />} />
                        <Route path=":box/:filter" element={<MessagingWrapper />} />
                      </Route>
                    </Route>
                    <Route path="events/:id" element={<EventContextProviderProxy />}>
                      <Route path="" element={<EventPortalContextProvider />}>
                        <Route path="" element={<EventForm />}>
                          <Route path="details" element={<EventDetails />} />
                          <Route path="emails" element={<MessageContextProvider />}>
                            <Route path=":box" element={<Messaging />} />
                          </Route>
                          <Route path="" element={<EventResourcePlanningContextProvider />}>
                            <Route path="resources" element={<EventResources />} />
                          </Route>
                          <Route path="contracts" element={<Contracts />} />
                          <Route path="finance" element={<EventProfit />} />

                          <Route path="documents" element={<EventDocuments />} />
                          <Route path="shares" element={<SharePane />} />

                          <Route path="documents" element={<DocumentContextProviderProxy />}>
                            <Route path=":documentId" element={<EventDocumentForm />} />
                            <Route path=":documentId/preview" element={<DocumentPreview />} />
                          </Route>

                          <Route path="questionnaires" element={<QuestionnaireContextProviderProxy />}>
                            <Route path=":qid" element={<QuestionnaireForm />} />
                            <Route path=":qid/preview" element={<QuestionnairePreview />} />
                          </Route>

                          <Route path="contracts" element={<ContractContextProviderProxy />}>
                            <Route path=":contractId" element={<EventContractForm />} />
                            <Route path=":contractId/preview" element={<ContractPreview />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    <Route path="academy" element={<AcademyPage />}>
                      <Route path="welcome" element={<AcademyLandingPage />} />
                      <Route path="channels/:channelName" element={<AcademyChannel />} />
                    </Route>

                    <Route path="lxo-auth" element={<LoxOAuthDispatcher />} />
                    <Route path="azure-oauth" element={<AzureOAuthDispatcher />} />

                    <Route path="settings" element={<Settings />}>
                      <Route path="userdata" element={<BankAccountContextProvider />} />
                      <Route path="recruitment" element={<RecruitmentCampaign />} />
                      <Route path="plans" element={<PackageSection />} />
                      <Route path="bills" element={<Bills />} />
                      <Route path="customfields" element={<CustomFields />} />
                      <Route path="eventstates" element={<EventStates />} />
                      <Route path="eventkinds" element={<EventKinds />} />
                      <Route path="bookkeeping" element={<Accounting />} />
                      <Route path="google" element={<GoogleSection />} />
                      <Route path="resources" element={<ResourcesPane />} />
                      <Route path="workflow" element={<Workflow />} />
                      <Route path="workflow/templates/:id" element={<WorkflowTemplate />} />
                      <Route path="templates" element={<DocumentTemplateList />} />
                      <Route path="contracts" element={<ContractTemplates />} />
                      <Route path="questionnaires" element={<QuestionnaireTemplates />} />
                      <Route path="" element={<DocumentLayoutContextProviderProxy />}>
                        <Route path="documents" element={<DocumentSettings />} />
                      </Route>
                      <Route path="template" element={<Outlet />}>
                        <Route path="contract" element={<ContractTemplateContextProvider />}>
                          <Route path=":templateName" element={<ContractTemplate />} />
                        </Route>
                        <Route path="questionnaire" element={<QuestionnaireTemplateContextProvider />}>
                          <Route path=":templateName" element={<QuestionnaireTemplate />} />
                        </Route>
                        <Route path="message" element={<MessageTemplateProvider />}>
                          <Route path=":type" element={<EmailTemplateEditorSkeleton />} />
                          <Route path=":type/:name" element={<EmailTemplateEditorSkeleton />} />
                        </Route>
                        <Route path="document/:type/:id" element={<DocumentTemplate />} />
                      </Route>
                      <Route path="messages" element={<MessageTemplateList />} />
                      <Route path="signature" element={<SignaturePane />} />
                      <Route path="mail-account" element={<MailAccount />} />
                      <Route path="files" element={<PromoMaterial />} />
                      <Route path="connectwebsite" element={<ConnectWebsiteProxy />} />

                      <Route path="contact-form" element={<ContactFormList />} />
                      <Route path="" element={<BookitupFormContextProvider />}>
                        <Route path="contact-form/:id" element={<ContactForm />} />
                      </Route>
                      <Route path="gdpr" element={<GDPR />} />
                      <Route path="calendar" element={<CalendarConfig />} />
                      <Route path="sequentialnumbers/" element={<SequentialNumbers />} />
                      <Route path="taxes" element={<Taxes />} />
                      <Route path="portal" element={<PortalLayout />} />
                      <Route path="team" element={<Team />} />
                      <Route path="positions" element={<PositionsTable />} />
                      <Route path="account" element={<AccountSettings />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      )}
      {/* NON AUTH ROUTES  */}
      <Route path="" element={<AnnoucementsContextProvider showSeenStories />}>
        <Route path="" element={<BookitupTemplate />}>
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/registration-completed" element={<RegistrationCompleted />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/password-forgotten" element={<PasswordForgotten />} />
          <Route path="/verify-email" element={<EmailVerificationPage />} />
          <Route path="/reset-password" element={<PasswordResetPage />} />
          <Route path="/confirm-invitation" element={<ConfirmInvitationPage />} />
          <Route path="/recommendationRequest/:id" element={<RecommendationRequest />} />
        </Route>
      </Route>
    </Routes>
  );
};

export const ModalRoutes = {
  taskEditModal: TaskEditModal,
  taskCreateModal: TaskCreateModal,
  recurringTaskModalFlow: RecurringTaskModalFlow,
  customAttributeDeleteModalFlow: CustomAttributeDeleteModalFlow,
  shareModalFlow: ShareModalFlow,
  insertPortalLink: InsertPortalLink,
};

export default ApplicationRoutes;
