// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Select, Text, Loader } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { openModal } from '@mantine/modals';
import AvailabilityIcon from '../../Atoms/AvailabilityIcon';
import AvailableCheck from '../../Molecules/AvailableCheck';
import PanePaper from '../../Molecules/PanePaper';
import { EventContext } from '../../Service/Context/EventContext';
import { DataS } from '../../Service/DataS';
import BookingEvent from '../../Types/Event';
import BookingState from '../../Types/Enums/BookingState';
import ChangeRecommendedEventStateModal from './ChangeRecommendedEventStateModal';
import { EventS } from '../../Service/EventS';
import { CustomEventState } from '../../Types/EventT';
import Dot from '../../Atoms/Dot';
import { IconChevronDown } from '@tabler/icons';
import { useJwtClaims } from '../../Service/Context/AuthContext';
import { LicenceS } from '../../Service/LicenceS';
import EventKindSelect from '../../Molecules/Event/EventKindSelect';
import { LeadOriginSelectProxy } from '../../Molecules/Event/LeadOriginSelect';

const AvailabilityPane = () => {
  const { event, saveEvent, dates } = useContext<any>(EventContext);
  const { recommendationId, bookingState } = event as BookingEvent;
  const { licence } = useJwtClaims();
  const customStatesAvailable = LicenceS.hasSuitableLicence('INTERACTIVE', licence);

  const changeBookingState = (_bookingState: BookingState) => {
    if (recommendationId) {
      const currentState = i18n._(`recommendation.bookingState.${bookingState.toLowerCase()}`);
      const newState = i18n._(`recommendation.bookingState.${_bookingState.toLowerCase()}`);
      openModal({
        title: (
          <Text mt="md" size="xl" sx={{ lineHeight: 1.2 }}>
            Statusänderung von{' '}
            <Text span weight="bolder">
              {currentState}
            </Text>{' '}
            auf{' '}
            <Text span weight="bolder">
              {newState}
            </Text>
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: (
          <ChangeRecommendedEventStateModal
            recommendationId={recommendationId}
            newBookingState={_bookingState}
            onFinish={() => saveEvent({ bookingState: _bookingState })}
          />
        ),
      });
    } else {
      saveEvent({ bookingState: _bookingState });
    }
  };

  return (
    <PanePaper title="Allgemein">
      {dates.length > 0 && <AvailableCheck dates={dates} eventId={event.id} />}
      <Text size="xs" color="dimmed">
        Event status
      </Text>
      {customStatesAvailable && <CustomStateSelect />}
      {!customStatesAvailable && (
        <Select
          mb="sm"
          value={event.bookingState}
          data={DataS.getBookingStateOptions(recommendationId)}
          onChange={(state) => changeBookingState(state as BookingState)}
          icon={<AvailabilityIcon bookingState={event.bookingState} />}
        />
      )}
      <EventKindSelect />
      <LeadOriginSelectProxy />
    </PanePaper>
  );
};

const CustomStateSelect = () => {
  const { event, saveEvent } = useContext<any>(EventContext);
  const [eventStates, setEventStates] = useState<CustomEventState[]>([]);
  const [loading, setLoading] = useState(true);
  const { recommendationId, bookingState } = event;
  const data = DataS.getEventStateOptions(eventStates, recommendationId !== null);
  const dotColor = EventS.getStateColor(event);
  const value = EventS.getEventStateValue(event, eventStates);

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    const _eventStates = await EventS.fetchEventStates();
    setEventStates(EventS.sortCustomStates(_eventStates));
    setLoading(false);
  };

  const onChange = async (value: string) => {
    const selectedState = eventStates.find((s) => s.label === value);
    if (selectedState) {
      if (recommendationId) {
        const currentState = i18n._(`recommendation.bookingState.${bookingState.toLowerCase()}`);
        const newState = selectedState.id
          ? selectedState.label
          : i18n._(`recommendation.bookingState.${selectedState.originState.toLowerCase()}`);
        openModal({
          title: (
            <Text mt="md" size="xl" sx={{ lineHeight: 1.2 }}>
              Statusänderung von{' '}
              <Text span weight="bolder">
                {currentState}
              </Text>{' '}
              auf{' '}
              <Text span weight="bolder">
                {newState}
              </Text>
            </Text>
          ),
          closeOnEscape: true,
          transition: 'slide-down',
          children: (
            <ChangeRecommendedEventStateModal
              recommendationId={recommendationId}
              newBookingState={selectedState.originState}
              onFinish={() => {
                const patch = selectedState.id
                  ? { eventStateId: selectedState.id }
                  : { bookingState: selectedState.originState };
                saveEvent(patch);
              }}
            />
          ),
        });
      } else {
        const { id, originState } = selectedState;
        const patch = id ? { eventStateId: id } : { bookingState: originState };
        setLoading(true);
        await saveEvent(patch);
        setLoading(false);
      }
    }
  };

  return (
    <Select
      mb="sm"
      disabled={loading}
      value={value}
      data={data}
      icon={loading ? <Loader size="xs" /> : <Dot withoutShadow colour={dotColor} />}
      onChange={(val) => val && onChange(val)}
      styles={{ rightSection: { pointerEvents: 'none' } }}
      rightSection={<IconChevronDown size={14} />}
      rightSectionWidth={25}
    />
  );
};

export default AvailabilityPane;
