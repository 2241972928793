// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { EventContext } from '../../Service/Context/EventContext';

const EventBreadCrumbs: FC = () => {
  const { dates } = useContext<any>(EventContext);
  const withoutDates = dates.length === 0;

  return (
    <Stack spacing={3}>
      {withoutDates && <Text size="xl">Bisher kein Termin</Text>}
      {!withoutDates && (
        <>
          <Text>Event am</Text>
          <Text size="xl" weight="bolder">{`${dates[0].weekday}, ${new Intl.DateTimeFormat('de').format(
            new Date(dates[0].date),
          )}`}</Text>
        </>
      )}
    </Stack>
  );
};

export default EventBreadCrumbs;
