/* eslint-disable no-underscore-dangle */
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import iCalendarPlugin from '@fullcalendar/icalendar';
import { useContext, useEffect, useRef, useState } from 'react';
import { Box, LoadingOverlay } from '@mantine/core';
import deLocale from '@fullcalendar/core/locales/de';
import { EventContentArg } from '@fullcalendar/core';
import { WindowContext } from '../../Service/Context/WindowContext';
import { openTaskPreviewModal } from '../../Molecules/Event/Tasks/TaskModals';
import { ICalContext } from '../../Service/Context/ICalContext';
import { CalendarS } from '../../Service/CalendarS';

const EventsCalendarView = () => {
  const calendarRef = useRef<any>(null);
  const { xs } = useContext(WindowContext);
  const { setCalendar, loadTasks } = useContext(ICalContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (calendarRef) {
      setCalendar(calendarRef.current.getApi());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarRef]);

  const eventContent = (obj: EventContentArg) => {
    const currentView = obj.view.type;
    const eventDetails = obj.event._def;
    if (eventDetails.groupId === 'BOOKITUP_EVENTS') {
      return CalendarS.renderBookitupEvent(currentView ?? '', obj);
    }
    if (eventDetails.groupId === 'BOOKITUP_TASKS') {
      return CalendarS.renderTask(obj, () =>
        openTaskPreviewModal({
          taskId: eventDetails.publicId,
          onMarkAsCompleted: loadTasks,
        }),
      );
    }
    return CalendarS.renderPrivateEvent(currentView, obj);
  };

  return (
    <Box pos="relative">
      <LoadingOverlay visible={loading} overlayBlur={1} loaderProps={{ size: 'xl' }} />
      <FullCalendar
        nowIndicator
        initialView={xs ? 'listWeek' : 'dayGridMonth'}
        loading={setLoading}
        height={xs ? '80vh' : '90vh'}
        ref={calendarRef}
        locale={deLocale}
        headerToolbar={{
          left: 'title',
          right: xs ? undefined : 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        footerToolbar={{
          center: 'prev today next',
        }}
        plugins={[dayGridPlugin, iCalendarPlugin, listPlugin, timeGridPlugin]}
        eventContent={eventContent}
        eventClick={(eventClickInfo) => {
          if (eventClickInfo.event.url) {
            eventClickInfo.jsEvent.preventDefault();
            window.open(eventClickInfo.event.url, '_blank');
          }
        }}
      />
    </Box>
  );
};

export default EventsCalendarView;
