import { I18n } from '@lingui/core';
import { Badge } from '@mantine/core';
import { BillingAddon, BillingPlan } from '../Types/Billing';
import BookingState from '../Types/Enums/BookingState';
import { CustomAttributeGroupDTO, CustomAttributeWithGroupNameDTO } from '../Types/CustomAttributeT';
import { EntityReferenceDTO } from '../Types/LogT';
import { MessageDTO, MessageTemplateDto } from '../Types/MessageT';
import { EventKind, CustomEventState } from '../Types/EventT';
import { QuestionnaireTemplateDto } from '../Types/QuestionnaireT';
import { DocumentLayoutPlacement } from './PdfS';
import { Contact } from './ContactS';
import { Resource } from '../Types/ResourceT';
import SemanticFormStyle from '../Types/Enums/SemanticFormStyle';
import { LocationDto } from '../Types/LocationT';
import { LocationS } from './LocationS';
import { EventDate } from '../Types/Event';
import { EventS } from './EventS';
import { GoogleAccountDTO } from '../Types/GoogleT';

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface LabelValuePair {
  label: string;
  value: string;
}

const fontOptions = [
  { label: 'Helvetica', value: 'Helvetica' },
  { label: 'OpenSans', value: 'OpenSans' },
  { label: 'Calibri', value: 'Calibri' },
  { label: 'Baskerville', value: 'Baskerville' },
  { label: 'Raleway', value: 'Raleway' },
  { label: 'DejaVuSans', value: 'DejaVuSans' },
  { label: 'Montserrat', value: 'Montserrat' },
  { label: 'Share', value: 'Share' },
];

const getContactNameOptions = (infoBoxPlacement: DocumentLayoutPlacement) => [
  { label: 'Firmenname', value: 'COMPANY' },
  { label: 'Künstlername', value: 'ALIAS' },
  { label: 'keine Name', value: 'NONE', disabled: infoBoxPlacement !== 'RIGHT' },
];

const logoSizeOptions = [
  { label: 'XS', value: 'EXTRA_SMALL' },
  { label: 'S', value: 'SMALL' },
  { label: 'M', value: 'MEDIUM' },
  { label: 'L', value: 'BIG' },
  { label: 'XL', value: 'FULLSIZE' },
];

const boxPlacementOptions = [
  { label: 'keine Infos', value: 'NONE' },
  { label: 'Oben rechts', value: 'RIGHT' },
  { label: 'Unten zentriert', value: 'CENTER' },
  { label: 'Unten blockweise', value: 'JUSTIFIED' },
];

const pageNumberPositionOptions = [
  { label: 'Oben', value: 'TOP' },
  { label: 'Mittig', value: 'MIDDLE' },
  { label: 'Unten', value: 'BOTTOM' },
];

const eventSortingOptions = [
  { label: 'Chronologisch', value: 'chronological' },
  { label: 'Letzte Anfragen', value: 'createdAt' },
];

const recommendationOrderOptions = [
  { label: 'Alle', value: 'chronological' },
  { label: 'Nur übermittelte', value: 'createdAt' },
  { label: 'Nur nicht übermittelte', value: 'chronological' },
];

const getBookingStateOptions = (recommendationId?: string) => {
  const options = [];
  options.push(
    { label: 'Anfrage', value: BookingState.OPEN },
    { label: 'Option', value: BookingState.OFFERED },
    { label: 'Gebucht', value: BookingState.BOOKED },
  );
  if (!recommendationId) {
    options.push({ label: 'Nicht verfügbar', value: BookingState.UNAVAILABLE });
  }
  options.push({ label: 'Annulliert', value: BookingState.CANCELED });
  return options;
};

const servicesOptions = [
  { label: 'Fotograf:in', value: 'Fotograf:in' },
  { label: 'Videograf:in', value: 'Videograf:in' },
  { label: 'DJ', value: 'DJ' },
  { label: 'Sänger:in', value: 'Sänger:in' },
  { label: 'Dekorateuer:in', value: 'Dekorateuer:in' },
  { label: 'Florist:in', value: 'Florist:in' },
  { label: 'Location', value: 'Location' },
  { label: 'Catering', value: 'Catering' },
  { label: 'Freier Redner', value: 'Freier Redner' },
  { label: 'Zauberer', value: 'Zauberer' },
  { label: 'Saxophonist', value: 'Saxophonist' },
  { label: 'Make-up & Hair Styling', value: 'Make-up & Hair Styling' },
  { label: 'Eventtechnik', value: 'Eventtechnik' },
  { label: 'Fotobox-Verleih', value: 'Fotobox-Verleih' },
  { label: 'Agentur', value: 'Agentur' },
  { label: 'Band', value: 'Band' },
  { label: 'Sonstiges', value: 'Sonstiges' },
];

const howYouKnowUsOptions = [
  { label: 'Anzeige bei Facebook', value: 'FACEBOOK' },
  { label: 'Anzeige bei Instagram', value: 'INSTAGRAM' },
  { label: 'Anzeige bei YouTube', value: 'YOUTUBE' },
  { label: 'lexoffice', value: 'LEX_OFFICE' },
  { label: 'Suchmaschine', value: 'SEARCH_ENGINE' },
  { label: 'Berufsverband Discjockeys', value: 'DISCJOCKEYS_ASSOCIATION' },
  { label: 'DJ Workshop Germany', value: 'DJ_WORKSHOP' },
  { label: 'DJ Talk', value: 'DJ_TALK' },
  { label: 'Frau Immer & Herr Ewig', value: 'FRAU_IMMER_HERR_EWIG' },
  { label: 'Persönliche Empfehlung', value: 'PERSONAL_RECOMMENDATION' },
  { label: 'Sonstiges', value: 'OTHER' },
];

const billingAddons: BillingAddon[] = [
  {
    name: 'Resource-Planning',
    description:
      'Mit dem "Resource Planning" Addon kannst Du Vermietartikel verwalten, in Deinen Events einplanen und in einer Zeitleiste Überschneidungen, sowie Überbuchungen sofort erkennen. Du siehst schon bei der Anfrage, welche Ressourcen noch verfügbar sind und kannst zudem nichts mehr vergessen.',
    price: {
      Monthly: [
        {
          from: 1,
          to: 10,
          price: 10,
        },
        {
          from: 1,
          to: 50,
          price: 15,
        },
        {
          from: 51,
          to: 100,
          price: 20,
        },
        {
          from: 101,
          to: 250,
          price: 25,
        },
        {
          from: 251,
          to: 500,
          price: 30,
        },
      ],
      Yearly: [
        {
          from: 1,
          to: 10,
          price: 5,
        },
        {
          from: 11,
          to: 50,
          price: 10,
        },
        {
          from: 51,
          to: 100,
          price: 15,
        },
        {
          from: 101,
          to: 250,
          price: 20,
        },
        {
          from: 251,
          to: 500,
          price: 25,
        },
      ],
    },
  },
];

const billingPlans: BillingPlan[] = [
  {
    name: 'Starter',
    internalName: 'foo',
    licence: 'BASIC',
    free: true,
    price: {
      Monthly: 0,
      Yearly: 0,
    },
    topFeaturesText: 'Verwalte Deine Events mit diesen Funktionen:',
    features: [
      'Event-Übersicht',
      '1 Kontaktformular',
      'Verfügbarkeits-Check',
      '1 privaten Kalender prüfen',
      'Angebote & Rechnungen',
      'E-Mails im Dienstleister-Netzwerk',
      'E-Mail Postfach',
      'Kalender-Abo über Deine Bookings',
      'Kalender-Abo über Deine Vermittlungen',
    ],
  },
  {
    name: 'Essentials',
    internalName: 'Pro',
    licence: 'PRO',
    price: {
      Monthly: 16,
      Yearly: 13,
    },
    topFeaturesText: 'Alle Funktionen von Starter, plus:',
    topFeaturesComponent: (
      <Badge mb="md" radius="sm" sx={{ color: 'white' }} variant="gradient">
        inkl. Mustervertrag für DJs
      </Badge>
    ),
    features: [
      'Eigene Felder',
      'Fragebögen',
      'Verträge',
      'Abschlagsrechnungen',
      'Schlussrechnungen',
      'Zahlungen erfassen',
      'Aufgaben',
      'Eigene Vorlagen für Dokumente',
      'Dateien hochladen',
      'lexoffice Anbindung',
    ],
  },
  {
    name: 'Standard',
    internalName: 'Interactive',
    licence: 'INTERACTIVE',
    price: {
      Monthly: 30,
      Yearly: 25,
    },
    topFeaturesText: 'Alle Funktionen von Essentials, plus:',
    features: [
      '∞ Kontaktformular erstellen',
      '∞ externe private Kalender',
      'Kontakte-Sync zu Google',
      'Aufgaben-Sync zu Google',
      'Workflows / Automationen',
      'Eigene Event-Status',
      'Agenturmodus',
      'Aut. Verf.-Prüfung im Netzwerk',
      'Account-Freigaben für Teams',
      'Eigene Vorlagen für E-Mails',
      'E-Mails planen',
      'Statistiken',
      'Zapier-Anbindung in Kontaktformular',
      'Online Kundenportal',
      'Online Verträge',
      'Online Fragebögen',
      'Online Fragebögen Sync mit eigenen Feldern',
      'Online Angebote',
      'Online Auftragsbestätigungen',
      'Online Rechnungen',
      'Online PDF Freigabe',
    ],
  },
];

const billingIntervals = [
  { label: 'Monatlich', value: 'Monthly' },
  { label: 'Jährlich', value: 'Yearly' },
];

const paymentUnitOptions = [
  { label: '%', value: 'PERCENTAGE' },
  { label: '€', value: 'EURO' },
];

const documentTypeOptions = [
  { label: 'Angebot', value: 'OFFER' },
  { label: 'Auftragsbestätigung', value: 'CONFIRMATION' },
  { label: 'Rechnung', value: 'INVOICE' },
  { label: 'Abschlagsrechnung', value: 'PARTIAL_INVOICE' },
  { label: 'Rechnungskorrektur', value: 'CANCEL' },
];

const discountUnitOptions = [
  { label: '%', value: 'PERCENT' },
  { label: '€', value: 'ABSOLUTE' },
];

const netGrossOptions = [
  { label: 'Brutto', value: 'gross' },
  { label: 'Netto', value: 'net' },
];

const taxRateOptions = [
  { label: '19', value: 19 },
  { label: '7', value: 7 },
  { label: '0', value: 0 },
];

const shippingTypeOptions = [
  { label: 'Kein Lieferdatum anzeigen', value: 'none' },
  { label: 'Lieferdatum', value: 'delivery' },
  { label: 'Leistungsdatum', value: 'service' },
  { label: 'Lieferzeitraum', value: 'deliveryperiod' },
  { label: 'Leistungszeitraum', value: 'serviceperiod' },
];

const getAccountingWebsiteOptions = (i18n: I18n) => [
  { label: i18n._('profile.accoutingTool.lexOffice'), value: 'LexOffice' },
  { label: i18n._('profile.accoutingTool.sevDesk'), value: 'sevDesk' },
  { label: i18n._('profile.accoutingTool.debitoor'), value: 'Debitoor' },
  { label: i18n._('profile.accoutingTool.datev'), value: 'DATEV' },
  { label: i18n._('profile.accoutingTool.candis'), value: 'Candis' },
  { label: i18n._('profile.accoutingTool.other'), value: 'Other' },
];

const documentStatusOptions = [
  { label: 'Alle', value: 'undefined' },
  { label: 'Entwurf', value: 'true' },
  { label: 'Fertig', value: 'false' },
];

const documentInvoicePaymentStatusOptions = [
  { label: 'Alle', value: 'undefined' },
  { label: 'Zahlung erhalten', value: 'true' },
  { label: 'keine Zahlung', value: 'false' },
];

const removeEmptyValues = (obj: any) =>
  Object.keys(obj).reduce((acc, key) => {
    const _acc = acc as any;
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {});

const resourceTypeOptions = [
  {
    label: 'Item',
    value: 'ITEM',
    description: 'Eine einzelne Ressource, wie ein Kerzenständer oder ein Lautsprecher.',
  },
  {
    label: 'Set',
    value: 'KIT',
    description: 'Eine logische Sammlung von Ressourcen, wie ein Hochzeitspaket für bis zu 100 Gästen.',
  },
  {
    label: 'Case',
    value: 'CASE',
    description:
      'Eine Sammlung von Ressourcen, die nur zusammen genutzt werden, wie eine Fotobox mit verbauter Kamera.',
  },
];

const resourceUsageOptions = [
  { label: 'RENTAL', value: 'RENTAL' },
  { label: 'SALE', value: 'SALE' },
  { label: 'CONSUMABLE', value: 'CONSUMABLE' },
];

export type ShareGroup = {
  category: string;
  messages: string[];
};

const getSharedMessageGroupCategoryOptions = (name: string) => [
  {
    label: 'Allgemein',
    value: `Wie organisierst du eigentlich deine ganzen Kundendaten? Ich nutze dafür bookitup! Es ist eine 360° Eventverwaltungssoftware, die für dich alles im Blick behält. Mit bookitup kannst Du Angebote und Rechnungen schreiben, Notizen und Dateien hinterlegen und sogar online Verträge anbieten. Das Beste daran ist, dass bookitup auch einige Automatisierungsfunktionen bietet, die Dir viel Zeit und Mühe sparen können. \n\nIch persönlich nutze bookitup für alle meine Eventplanungen und ich muss sagen, ich bin mehr als zufrieden damit. Es ist einfach zu bedienen und spart mir jede Menge Zeit. Ich habe meine Arbeitsprozesse optimiert und kann mich auf das Wesentliche konzentrieren - nämlich ein großartiges Event zu organisieren.\n\nProbiere es doch einfach mal aus und lasse mich wissen, was Du davon hältst. Über folgenden Link kannst Du Dich registrieren: \n\n`,
  },
  {
    label: 'DJs',
    value: `Hey Leute, \n\nich möchte Euch heute eine geniale Software vorstellen, die das Planen von Events um einiges einfacher und schneller macht. Es handelt sich um bookitup, die 360° Eventverwaltungssoftware, die ich als DJ seit einiger Zeit nutze und absolut empfehlen kann. \n\nMit bookitup kann ich nicht nur Angebote und Rechnungen schreiben, sondern auch meine Playlist und meine Termine verwalten. Auch das Speichern von Notizen und Dateien sowie das Anbieten von Online-Verträgen wird von bookitup unterstützt. Und das Beste: bookitup bietet auch einige Automatisierungsfunktionen, die mir viel Zeit und Arbeit ersparen.\n\nAls DJ ist es wichtig, dass ich meine Events im Griff habe und jederzeit den Überblick behalte. Mit bookitup kann ich meine Arbeitsprozesse optimieren und mich auf das Wesentliche konzentrieren - nämlich auf die Musik und die Stimmung auf dem Event.\n\nIch kann Euch bookitup also wirklich nur empfehlen, probiert es aus und erleichtert Euch das Event-Management.\n\nKeep the party going!\n\nViele Grüße,\n${name}\n\n`,
  },
  {
    label: 'Fotografen',
    value: `Hallo liebe Kollegen,\n\nich wollte Euch heute eine Software empfehlen, die mir als Hochzeitsfotograf sehr dabei geholfen hat, meine Arbeit besser zu organisieren und zu verwalten. Es handelt sich um bookitup, die 360° Eventverwaltungssoftware, die ich seit einiger Zeit nutze und die ich absolut empfehlen kann. \n\nMit bookitup kann ich Angebote und Rechnungen schreiben, meine Termine und Aufträge verwalten und auch Notizen und Dateien speichern. Besonders praktisch finde ich auch die Möglichkeit, Online-Verträge anzubieten und zu verwalten. Und das Beste: bookitup bietet auch einige Automatisierungsfunktionen, die meine Arbeitsprozesse enorm vereinfacht haben. \n\nAls Hochzeitsfotograf ist es wichtig, dass ich stets den Überblick über meine Termine und Aufträge habe, und mit bookitup ist das einfach und unkompliziert möglich. Die Software ist sehr intuitiv und einfach zu bedienen, und hat mir schon viel Zeit und Nerven gespart. \n\nIch kann Euch bookitup also wirklich nur empfehlen, probiert es aus und erleichtert Euch die Hochzeitsfotografie.\n\nViele Grüße,\n${name}\n\n`,
  },
  {
    label: 'Sänger',
    value: `Hey Leute,\n\nich wollte Euch heute eine großartige Software empfehlen, die mir als Sänger sehr dabei geholfen hat, meine Auftritte besser zu organisieren und zu verwalten. Es handelt sich um bookitup, die 360° Eventverwaltungssoftware, die ich seit einiger Zeit nutze und absolut empfehlen kann. \n\nMit bookitup kann ich Angebote und Rechnungen schreiben, meine Termine und Aufträge verwalten und auch Notizen und Dateien speichern. Besonders praktisch finde ich auch die Möglichkeit, Online-Verträge anzubieten und zu verwalten. Und das Beste: bookitup bietet auch einige Automatisierungsfunktionen, die meine Arbeitsprozesse enorm vereinfacht haben. \n\nAls Sänger ist es wichtig, dass ich meine Auftritte und Termine gut koordiniere und den Überblick behalte. Mit bookitup kann ich meine Arbeitsprozesse optimieren und mich auf das Wesentliche konzentrieren - nämlich auf meine Musik und meine Auftritte. \n\nIch kann Euch bookitup also wirklich nur empfehlen, probiert es aus und erleichtert Euch das Event-Management.\n\nViele Grüße,\n${name}\n\n`,
  },
];

const contactTypeOptions = [
  { label: '👤 Privat', value: 'PRIVATE' },
  { label: '🏢 Firma', value: 'COMPANY' },
];

const getContactsOptions = (contacts: Contact[]) => contacts.map(getContactOption);

const getContactOption = (contact: Contact) => {
  const { id, personDescription } = contact;
  return { value: id, label: personDescription || '' };
};

const getAttributeTypeOptions = (i18n: I18n) => [
  { value: 'BOOLEAN', label: i18n._('custom-attribute.boolean') },
  { value: 'STRING', label: i18n._('custom-attribute.string') },
  { value: 'TEXT', label: i18n._('custom-attribute.text') },
  { value: 'DATE', label: i18n._('custom-attribute.date') },
  { value: 'TIME', label: i18n._('custom-attribute.time') },
  { value: 'NUMBER', label: i18n._('custom-attribute.number') },
  { value: 'DECIMAL', label: i18n._('custom-attribute.decimal') },
  { value: 'MULTIPLE_CHOICE', label: i18n._('custom-attribute.multiple_choice') },
];

const getAttributeGroupsOptions = (groups: CustomAttributeGroupDTO[]) =>
  groups.map((group) => ({ value: group.id, label: group.name }));

const distanceRadiusOptions = [
  { label: '< 50 km', value: '50' },
  { label: '< 100 km', value: '100' },
  { label: '< 150 km', value: '150' },
  { label: '< 200 km', value: '200' },
];

const getActionTypeData = (i18n: I18n, alternativeStep: boolean) => {
  const options = [
    { value: 'SEND_MAIL', label: i18n._('actionType.SEND_MAIL') },
    { value: 'CREATE_DOCUMENT', label: i18n._('actionType.CREATE_DOCUMENT') },
    { value: 'CREATE_QUESTIONNAIRE', label: i18n._('actionType.CREATE_QUESTIONNAIRE') },
    { value: 'CREATE_CONTRACT', label: i18n._('actionType.CREATE_CONTRACT') },
    { value: 'CREATE_SHARE', label: i18n._('actionType.CREATE_SHARE') },
    { value: 'SHARE_ENTITY', label: i18n._('actionType.SHARE_ENTITY') },
    { value: 'CHANGE_EVENT_STATUS', label: i18n._('actionType.CHANGE_EVENT_STATUS') },
    { value: 'CREATE_TASK', label: i18n._('actionType.CREATE_TASK') },
    { value: 'COMPLETE_TASK', label: i18n._('actionType.COMPLETE_TASK') },
    { value: 'DELETE_TASK', label: i18n._('actionType.DELETE_TASK') },
    { value: 'DELETE_TASKS', label: i18n._('actionType.DELETE_TASKS') },
    { value: 'WAIT_UNTIL', label: i18n._('actionType.WAIT_UNTIL') },
  ];
  if (alternativeStep) {
    options.pop();
  }
  return options;
};

const getChronoUnitsData = (i18n: I18n) => [
  { value: 'MINUTES', label: i18n._('chronoUnit.MINUTES') },
  { value: 'HOURS', label: i18n._('chronoUnit.HOURS') },
  { value: 'DAYS', label: i18n._('chronoUnit.DAYS') },
  { value: 'WEEKS', label: i18n._('chronoUnit.WEEKS') },
  { value: 'MONTHS', label: i18n._('chronoUnit.MONTHS') },
];

const getExecutionStrategyOptions = (i18n: I18n) => [
  { value: 'AFTER_PREVIOUS_STEP', label: i18n._('execution.AFTER_PREVIOUS_STEP') },
  { value: 'BEFORE_EVENT_START', label: i18n._('execution.BEFORE_EVENT_START') },
  { value: 'AFTER_EVENT_START', label: i18n._('execution.AFTER_EVENT_START') },
  { value: 'BEFORE_EVENT_END', label: i18n._('execution.BEFORE_EVENT_END') },
  { value: 'AFTER_EVENT_END', label: i18n._('execution.AFTER_EVENT_END') },
];

const getEntityReferencesOptions = (availableEntities: EntityReferenceDTO[]) =>
  availableEntities.map((entity) => ({ value: entity.id, label: entity.label }));

const mailRecipientTypeOptions = [
  { value: 'PRIMARY_CONTACT', label: 'Hauptansprechpartner' },
  // { value: 'ALL_PARTICIPANTS', label: 'Alle Teilnehmer' },
];

const getMessageTemplateOptions = (templates: MessageTemplateDto[], i18n: I18n) => {
  const mapped: LabelValuePair[] = templates.map((template) => {
    const { id, messageType, name } = template;
    return {
      value: id.toString(),
      label: messageType === 'CUSTOM' ? name : i18n._(`message.type.${messageType.toLowerCase()}`),
    };
  });
  mapped.sort(compareByLabel);
  return mapped;
};

const compareByLabel = (a: LabelValuePair, b: LabelValuePair) => {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

const getObservableEventOptions = (i18n: I18n) => [
  { value: 'CONTRACT_SIGNED', label: i18n._('events.CONTRACT_SIGNED') },
  { value: 'OFFER_CONFIRMED', label: i18n._('events.OFFER_CONFIRMED') },
  { value: 'QUESTIONNAIRE_FILLED', label: i18n._('events.QUESTIONNAIRE_FILLED') },
  { value: 'INVOICE_NOT_PAID', label: i18n._('events.INVOICE_NOT_PAID') },
  { value: 'OFFER_OVERDUE', label: i18n._('events.OFFER_OVERDUE') },
  { value: 'MAIL_RECEIVED', label: i18n._('events.MAIL_RECEIVED') },
];

const getEventKindsOptions = (kinds: EventKind[]) => {
  const options: LabelValuePair[] = [];
  kinds.forEach((kind) => {
    const { name } = kind;
    options.push({ value: name, label: name });
  });
  return options;
};

const getCustomAttributeOptions = (customAttributes: CustomAttributeWithGroupNameDTO[]) =>
  customAttributes.map((attribute) => {
    const { name } = attribute;
    return { ...attribute, value: name, label: name };
  });

const getResourceOptions = (resources: Resource[]) =>
  resources.map((resource) => {
    const { name, id } = resource;
    return { ...resource, value: id, label: name };
  });

const getQuestionnaireTemplatesOptions = (templates: QuestionnaireTemplateDto[]) =>
  templates.map((template) => ({ value: template.id, label: template.name }));

const getContractTemplatesOptions = (templates: any[]) =>
  templates.map((template) => ({ value: template.id.toString(), label: template.name }));

const bookitupFormStyleOptions = [
  // { label: 'Brand', value: SemanticFormStyle.bookitup },
  // { label: 'Cosmos', value: SemanticFormStyle.cosmos },
  // { label: 'Lily', value: SemanticFormStyle.lily },
  // { label: 'Minimal', value: SemanticFormStyle.minimal },
  { label: 'Dunkel', value: SemanticFormStyle.DARK },
  { label: 'Hell', value: SemanticFormStyle.LIGHT },
];

const buttonPositionOptions = [
  { label: 'Links', value: 'left' },
  { label: 'Zentriert', value: 'center' },
  { label: 'Rechts', value: 'right' },
];

const getMailProviderOptions = (i18n: I18n) => [
  { value: 'gmail', label: i18n._('settings.mail.provider.gmail') },
  { value: 'gmx', label: i18n._('settings.mail.provider.gmx') },
  { value: 'strato', label: i18n._('settings.mail.provider.strato') },
  { value: 'web-de', label: i18n._('settings.mail.provider.web-de') },
  { value: 'office365', label: i18n._('settings.mail.provider.office365') },
  { value: 'outlook', label: i18n._('settings.mail.provider.outlook') },
  { value: 'hotmail', label: i18n._('settings.mail.provider.hotmail') },
  { value: 'djbaukasten', label: i18n._('settings.mail.provider.djbaukasten') },
  { value: 't-online', label: i18n._('settings.mail.provider.t-online') },
  { value: '1und1', label: i18n._('settings.mail.provider.1und1') },
  { value: 'jimdo', label: i18n._('settings.mail.provider.jimdo') },
  { value: 'yahoo', label: i18n._('settings.mail.provider.yahoo') },
  { value: '1blu', label: i18n._('settings.mail.provider.1blu') },
  { value: 'united-domains', label: i18n._('settings.mail.provider.united-domains') },
  { value: 'hetzner', label: i18n._('settings.mail.provider.hetzner') },
  { value: 'mail-de', label: i18n._('settings.mail.provider.mail-de') },
  { value: 'icloud', label: i18n._('settings.mail.provider.icloud') },
  { value: 'freenet', label: i18n._('settings.mail.provider.freenet') },
  { value: 'goneo', label: i18n._('settings.mail.provider.goneo') },
  { value: 'webador', label: i18n._('settings.mail.provider.webador') },
  { value: 'one-com', label: i18n._('settings.mail.provider.one-com') },
  { value: 'ovh', label: i18n._('settings.mail.provider.ovh') },
  { value: 'other', label: i18n._('settings.mail.provider.other') },
];

const getMailPortOptions = () => [
  { value: '25', label: '25' },
  { value: '465', label: '465' },
  { value: '587', label: '587' },
];

const getRecipientOptions = (contacts: Contact[], message: MessageDTO) => {
  const availableOptions: any[] = contacts
    .filter((c) => c.email)
    .map((c) => ({
      ...c,
      value: c.email,
      label: c.personDescription ?? c.email,
    }));

  const exists = (email: string) => availableOptions.find((o) => o.value === email) !== undefined;

  const { to, cc, bcc } = message;
  if (to) {
    to.forEach((id) => {
      if (!exists(id.email)) {
        availableOptions.push({
          personDescription: id.personDescription ?? id.email,
          value: id.email,
          label: id.email,
        });
      }
    });
  }
  if (cc) {
    cc.forEach((id) => {
      if (!exists(id.email)) {
        availableOptions.push({
          personDescription: id.personDescription ?? id.email,
          value: id.email,
          label: id.email,
        });
      }
    });
  }
  if (bcc) {
    bcc.forEach((id) => {
      if (!exists(id.email)) {
        availableOptions.push({
          personDescription: id.personDescription ?? id.email,
          value: id.email,
          label: id.email,
        });
      }
    });
  }
  return availableOptions;
};

const getEventStateOptions = (states: CustomEventState[], isRecommendation = false) =>
  states
    .filter((s) => (isRecommendation ? s.originState !== BookingState.UNAVAILABLE : true))
    .map((state) => {
      const { label } = state;
      return { value: label, label };
    });

const getEventStateOptionsV2 = (states: CustomEventState[]) =>
  states.map((state) => {
    const { id, label, originState } = state;
    return { value: id ?? originState, label };
  });

const getLocationOptions = (locations: LocationDto[]) =>
  locations.map((location) => {
    const { id } = location;
    const label = LocationS.getDescription(location) ?? '';
    return { value: id ? id.toString() : '', label };
  });

const getEventDatesOptions = (eventDates: EventDate[]) =>
  eventDates.map((eventDate) => {
    const { id, date, timeFrom, timeTo } = eventDate;
    const label = `${EventS.formatEventDate(date)}${timeFrom && timeTo ? `, ${timeFrom} - ${timeTo}` : ''}`;
    return { value: id.toString(), label };
  });

const eventDateTypeOpts = [
  { value: 'REGULAR_DATE', label: 'Datum' },
  { value: 'APPOINTMENT', label: 'Termin' },
];

const scheduleMailOptions = [
  { label: 'In 1 Stunde', value: 'HOUR' },
  { label: 'In 2 Stunden', value: 'TWO_HOUR' },
  { label: 'Morgen früh (8 Uhr)', value: 'TOMORROW_MORNING' },
  { label: 'Morgen Nachmittag (13 Uhr)', value: 'TOMORROW_AFTERNOON' },
  { label: 'Benutzerdefiniert', value: 'CUSTOM' },
];

const mailProviderHints = [
  // {
  //   provider: 'gmail',
  //   appSpecificPasswordLink: 'https://myaccount.google.com/u/1/apppasswords',
  //   twoStepVerificationLink: 'https://myaccount.google.com/u/1/signinoptions/two-step-verification',
  // },
  {
    provider: 'gmx',
    text: 'Zudem musst Du in den E-Mail-Einstellungen von GMX unter "POP3/IMAP Abruf" die Checkbox "POP3 und IMAP Zugriff erlauben" aktivieren. Um die E-Mail-Einstellungen zu öffnen, gehe im GMX Webmailer auf Deine E-Mails und klicke unten rechts auf das Zahnrad.',
    appSpecificPasswordLink: 'https://hilfe.gmx.net/sicherheit/2fa/anwendungsspezifisches-passwort.html',
    twoStepVerificationLink: null,
  },
  {
    provider: 'web-de',
    text: 'Für diesen Anbieter musst Du in den E-Mail-Einstellungen von web.de unter "POP3/IMAP Abruf" die Checkbox "POP3 und IMAP Zugriff erlauben" aktivieren. Um die E-Mail-Einstellungen zu öffnen, gehe im web.de Webmailer auf Deine E-Mails und klicke unten rechts auf das Zahnrad.',
    appSpecificPasswordLink: undefined, // necessary?
    twoStepVerificationLink: null,
  },
  {
    provider: 't-online',
    appSpecificPasswordLink: 'https://email.t-online.de/pr?a=passwords.emailpassword',
    twoStepVerificationLink: null,
  },
  {
    provider: '1blu',
    text: 'Damit Deine 1blu Mails funktionieren muss Dein Benutzername dein (vollständiger) Postfachname sein - dies ist NICHT Deine E-Mail-Adresse. Den Postfachnamen findest du z.B. im Kundencenter von 1blu in den E-Mail-Einstellungen. In unserem Test-1blu-Konto ist dieser beispielsweise a333102_0-info',
    appSpecificPasswordLink: null,
    twoStepVerificationLink: null,
  },
  {
    provider: 'yahoo',
    text: 'Yahoo unterstützt keine Antwort-an-E-Mail-Adresse. Antworten Deiner Kunden landen damit nicht automatisch in bookitup. Daher empfehlen wir hier ein anderes E-Mail-Konto zu verwenden.',
    appSpecificPasswordLink: 'https://login.yahoo.com/account/security/app-passwords/list',
    twoStepVerificationLink: null,
  },
  // {
  //   provider: 'office365',
  //   text: 'Damit Du Dein Office365 Konto verbinden kannst, ist es notwendig, dass du das in den Office365-Einstellungen erlaubst. Kontaktiere hierzu bei Problemen gerne unseren Support.',
  //   appSpecificPasswordLink: null,
  //   twoStepVerificationLink: null,
  // },
];

const mapGoogleAccounts = (accounts: GoogleAccountDTO[]) =>
  accounts.map((acc) => ({ label: acc.email, value: acc.id.toString() }));

// eslint-disable-next-line import/prefer-default-export
export const DataS = {
  fontOptions,
  getContactNameOptions,
  logoSizeOptions,
  boxPlacementOptions,
  pageNumberPositionOptions,
  eventSortingOptions,
  recommendationOrderOptions,
  getBookingStateOptions,
  servicesOptions,
  howYouKnowUsOptions,
  billingPlans,
  billingAddons,
  billingIntervals,
  paymentUnitOptions,
  documentTypeOptions,
  discountUnitOptions,
  netGrossOptions,
  taxRateOptions,
  shippingTypeOptions,
  getAccountingWebsiteOptions,
  documentStatusOptions,
  documentInvoicePaymentStatusOptions,
  removeEmptyValues,
  resourceTypeOptions,
  resourceUsageOptions,
  getSharedMessageGroupCategoryOptions,
  contactTypeOptions,
  getContactOption,
  getContactsOptions,
  getAttributeTypeOptions,
  getAttributeGroupsOptions,
  distanceRadiusOptions,
  getActionTypeData,
  getChronoUnitsData,
  getExecutionStrategyOptions,
  getEntityReferencesOptions,
  mailRecipientTypeOptions,
  getMessageTemplateOptions,
  getObservableEventOptions,
  getEventKindsOptions,
  getCustomAttributeOptions,
  getResourceOptions,
  getQuestionnaireTemplatesOptions,
  getContractTemplatesOptions,
  bookitupFormStyleOptions,
  buttonPositionOptions,
  getRecipientOptions,
  getMailProviderOptions,
  getMailPortOptions,
  getEventStateOptions,
  getEventStateOptionsV2,
  getLocationOptions,
  getEventDatesOptions,
  eventDateTypeOpts,
  scheduleMailOptions,
  mailProviderHints,
  mapGoogleAccounts,
};
